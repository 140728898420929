import { Modal, Table, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CrownOutlined, UserOutlined, TeamOutlined } from '@ant-design/icons';

const roleIcons = {
  'Super Admin': <CrownOutlined style={{ color: '#faad14' }} />,
  'Acquisitions Lead': <CrownOutlined style={{ color: '#faad14' }} />,
  'Acquisitions Team': <TeamOutlined style={{ color: '#1890ff' }} />,
  'Other': <UserOutlined style={{ color: '#818181' }} />
};

const getRoleIcon = (role: string) => roleIcons[role] || roleIcons['Other'];

const renderPermissionStatus = (text: boolean) => (
  <span style={{ backgroundColor: text ? 'rgba(158, 255, 201, 0.38)' : '', padding: '0 5px' }}>
    {text ? 'Yes' : 'No'}
  </span>
);

interface IRolePermission {
  user_role: string;
  view_tables: boolean;
  export_tables: boolean;
  edit_titles: boolean;
  delete_titles: boolean;
  move_titles_hubspot: boolean;
  hubspot_assignee: boolean;
  view_usage_stats: boolean;
  view_dashboard: boolean;
}

const ModalUserPermissions = ({
  open,
  setOpen,
  rolesAndPermissions,
  isLoadingRoles
}: {
  open: boolean;
  setOpen: (state: { open: boolean }) => void;
  rolesAndPermissions: IRolePermission[];
  isLoadingRoles: boolean;
}) => {
  const columns: ColumnsType<IRolePermission> = [
    {
      title: <b>User Role</b>,
      dataIndex: 'user_role',
      key: 'user_role',
      width: 180,
      fixed: 'left',
      render: (text: string) => (
        <span>
          {getRoleIcon(text)} <strong>{text}</strong>
        </span>
      )
    },
    {
      title: <b>View Tables</b>,
      dataIndex: 'view_tables',
      key: 'view_tables',
      width: 120,
      render: renderPermissionStatus
    },
    {
      title: <b>Export Tables</b>,
      dataIndex: 'export_tables',
      key: 'export_tables',
      width: 120,
      render: renderPermissionStatus
    },
    {
      title: <b>Edit Titles</b>,
      dataIndex: 'edit_titles',
      key: 'edit_titles',
      width: 120,
      render: renderPermissionStatus
    },
    {
      title: <b>Delete Titles</b>,
      dataIndex: 'delete_titles',
      key: 'delete_titles',
      width: 120,
      render: renderPermissionStatus
    },
    {
      title: <b>Move Titles to HubSpot</b>,
      dataIndex: 'move_titles_hubspot',
      key: 'move_titles_hubspot',
      width: 200,
      render: renderPermissionStatus
    },
    {
      title: <b>HubSpot Assignee</b>,
      dataIndex: 'hubspot_assignee',
      key: 'hubspot_assignee',
      width: 180,
      render: renderPermissionStatus
    },
    {
      title: <b>View Usage Stats</b>,
      dataIndex: 'view_usage_stats',
      key: 'view_usage_stats',
      width: 150,
      render: renderPermissionStatus
    },
    {
      title: <b>View Dashboard</b>,
      dataIndex: 'view_dashboard',
      key: 'view_dashboard',
      width: 150,
      render: renderPermissionStatus
    }
  ];

  return (
    <Modal
      title={<b>User Roles & Permissions</b>}
      visible={open}
      onCancel={() => setOpen({ open: false })}
      footer={null}
      width={1000}
    >
      {isLoadingRoles ? (
        <Spin tip='Loading roles...' style={{ color: '#00b27b' }} />
      ) : (
        <Table
          dataSource={rolesAndPermissions}
          columns={columns}
          rowKey='user_role'
          pagination={false}
          bordered
          scroll={{ x: 1000 }}
          className='roles-table-container'
        />
      )}
    </Modal>
  );
};

export default ModalUserPermissions;