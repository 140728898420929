import { Table as Tb } from 'antd';
import styled from 'styled-components';

const Footer = styled.div`

  display: flex;
  justify-content: space-between;

`;

export const TableFooter = ({ children }) => <Footer>{children}</Footer>;

const StyledTable = styled(Tb)`
  /* overflow: auto; */
  .ant-table-thead > tr > th {
    text-align: center !important;
    padding: 12px 6px !important;
    background: #f5f6f8;
    color: #818181;
    letter-spacing: 0.6px !important;
    font-size: 12px;
  }
  .ant-table-tbody > tr > td:not(:first-child) {
    text-align: center !important;
  }
`;
interface IProp {
  sticky?: boolean;
  y?: string | number;
  columns?: any;
  dataSource?: any;
  loading?: boolean;
  rowSelection?: any;
  components?: any;
  pagination?:boolean;
  style?:any;
  bordered?:boolean;
  x?:string | number;
  onRow?: Function;
}

const Table = ({ sticky = true, y = 500, x = 'max-content', ...props }: IProp) => (
  <StyledTable {...props} sticky={sticky} scroll={{ y, x }} />
);

export default Table;
