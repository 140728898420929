import React, { useState, useCallback } from 'react';
import { Modal, notification } from 'antd';
import AlsoBoughtContent from './AlsoBoughtContent';
import { useAlsoBoughtSearch } from '../../core/modules/book/hooks/useAlsoBought';
import { useQueryClient } from 'react-query';
import { GAEventIdentifier, GAEventName } from '../../section/Common/constants';

interface IAlsoBoughtProps {
  podiumHitCount: number;
  asin: string;
}

const AlsoBought: React.FC<IAlsoBoughtProps> = ({ podiumHitCount, asin }) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const alsoBoughts = useAlsoBoughtSearch({ onError: () => {
    notification.error({
      description: 'Failed to load data.',
      message: ''
    });
  } });

  const handleSearch = useCallback(() => {
    alsoBoughts.mutate(asin);
  }, []);

  const showAlsoBoughts = (e: React.MouseEvent<HTMLElement>) => {
    window.gtag('event', GAEventName.click,
      { identifier: GAEventIdentifier.podiumHitCount, value: asin });
    handleSearch();
    setOpen(true);
    e.stopPropagation();
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    queryClient.cancelQueries({ queryKey: ['get/also-bought'] });
    setOpen(false);
    e.stopPropagation();
  };

  return (
    <>
      <button
        data-testid={'also-boughts-count'}
        onClick={showAlsoBoughts}
        style={{ display: 'contents' }}>
        <span style={{
          display: 'contents',
          fontWeight: 500,
          cursor: 'pointer',
          color: podiumHitCount === -1 ?
            'none' : '#2ca880',
          pointerEvents: podiumHitCount === -1 ?
            'none' : 'auto'
        }}>{podiumHitCount > -1 ? podiumHitCount : '-'}</span>
      </button>

      <button className='basic-btn' onClick={(e) => e.stopPropagation()}>
        <Modal
          title='Also Bought Titles'
          okText='Ok'
          visible={open}
          onCancel={handleCancel}
          className={'also-bought-modal'}
          width={'90%'}
          footer={false}
        >
          <AlsoBoughtContent
            isLoading={alsoBoughts.isLoading}
            data={alsoBoughts?.data?.data}
          />
        </Modal>
      </button>
    </>
  );
};

export default AlsoBought;