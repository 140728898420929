import styled from 'styled-components';
import { useMemo, useState } from 'react';
import AddNewUser from './AddNewUser';
import UsersFilters from './UsersFilters';
import UsersPermissions from './UsersPermissions';
import { DeleteOutlined, FormOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { SpinnerTabs } from '../../composed-components';
import { useUserPermit } from '../../context/User.context';
import ModalUser from './ModalUser';
import ModalDelete from './ModalDelete';
import UserSort from './UserSort';
import Table from '../../components/Table';
import useAllUser from '../../core/modules/user/hooks/useAllUsers.query';
import useAllUserRoles from '../../core/modules/user/hooks/useAllUserRoles.query';
import { ROLES_WITH_ADMIN_PRIVILEGES } from '../../core/constants/global-constants';
import { GAEventIdentifier, GAEventName } from '../Common/constants';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledIcon = styled.div`
  display: flex;
  border-radius: 4px;
  width: 24px;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 3px;
  font-size: 16px !important;
  color: ${({ color }) => color};
  :hover {
    background: ${({ color }) => `${color}20`};
    cursor: pointer;
  }
`;

const ActiveIcon = styled.div`
  text-align: center;
  font-size: 16px !important;
  svg {
    fill: ${({ color }) => color};
  };
  width: 30px;
`;

const UsersSection = () => {
  const [modal, setModal] = useState({ open: false, user: {} });
  const [deleteModal, setDeleteModal] = useState({ open: false, user: {} });
  const { role } = useUserPermit();
  const { userRolesList, userRolesPermissions, isLoading: isLoadingRoles } = useAllUserRoles();

  const [filters, setFilters] = useState({});
  const [sorts, setSorts] = useState({
    sortBy: 'Last Name',
    sortType: 'ascending'
  });

  const hasAdminPrivileges = () => ROLES_WITH_ADMIN_PRIVILEGES.includes(role);

  const columns = useMemo(() => {
    const allColumns = [
      {
        title: () => (
          <UserSort
            title={'Last Name'}
            id={'Last Name'}
            sorts={sorts}
            setSort={setSorts}
          />
        ),
        dataIndex: 'last_name',
        key: 'Last Name'
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'First Name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'Email'
      },
      {
        title: () => (
          <UserSort
            title={'Role'}
            id={'Role'}
            sorts={sorts}
            setSort={setSorts}
          />
        ),
        dataIndex: 'role',
        key: 'Role'
      },
      {
        title: 'Is Active',
        dataIndex: 'active',
        key: 'Is Active',
        render: (_, u) =>
          u?.active ? (
            <ActiveIcon color='green'>
              <CheckCircleOutlined />
            </ActiveIcon>
          ) : (
            <ActiveIcon color='red'>
              <CloseCircleOutlined />
            </ActiveIcon>
          )
      }
    ];

    if (hasAdminPrivileges()) {
      allColumns.push({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, u) => (
          <div className='actions'>
            <StyledIcon
              color='#c923e5'
              onClick={() => {
                window.gtag('event', GAEventName.click,
                  { event_category: 'Interaction', identifier: GAEventIdentifier.createSuperUser }
                );
                setModal({ open: true, user: u });
              }}
            >
              <FormOutlined />
            </StyledIcon>
            <StyledIcon
              color='#f80505'
              onClick={() => {
                window.gtag('event', GAEventName.click,
                  { event_category: 'Interaction', identifier: GAEventIdentifier.deleteSuperUser }
                );
                setDeleteModal({ open: true, user: u });
              }}
            >
              <DeleteOutlined />
            </StyledIcon>
          </div>
        )
      });
    }

    return allColumns;
  }, [role, sorts]);

  const { data, isLoading } = useAllUser({ ...filters, ...sorts });
  const dataSource = data?.map(u => ({
    ...u,
    key: u.id
  }));

  return (
    <>
      <ModalUser
        user={modal?.user}
        open={modal?.open}
        setOpen={setModal}
        roles={userRolesList}
        isLoadingRoles={isLoadingRoles}
      />
      <ModalDelete
        setOpen={setDeleteModal}
        open={deleteModal?.open}
        user={deleteModal?.user}
      />
      <Wrapper>
        <UsersFilters setFilters={setFilters} roles={userRolesList} isLoadingRoles={isLoadingRoles} />
        {hasAdminPrivileges() && (
          <div className='manage-users-btn-wrapper'>
            <UsersPermissions rolesAndPermissions={userRolesPermissions} isLoadingRoles={isLoadingRoles} />
            <AddNewUser roles={userRolesList} isLoadingRoles={isLoadingRoles} />
          </div>
        )}
        {data?.length ? (
          <Table
            bordered
            dataSource={dataSource}
            pagination={false}
            columns={columns}
          />
        ) : (
          <SpinnerTabs isLoading={isLoading} />
        )}
      </Wrapper>
    </>
  );
};

export default UsersSection;