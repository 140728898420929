import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { notification, Popover } from 'antd';
import { IBook } from '../../section/Common/types/types';
import { CONTACTED, CONTACTED_RR, SOURCE } from '../../core/constants';
import { useUpdateTitle } from '../../core/modules/book/hooks/useUpdateTitle.query';
import { useActions } from '../../context/Actions.context';

interface IProps {
  book: IBook;
  refetchTable: Function;
  id: string;
}

const createContactedObject = (source: string, book: IBook) => {
  if (source === SOURCE.bookstat) {
    return { amazonasin: book.ASIN };
  } else {
    return { fiction_id: book['Fiction Id'] };
  }
};

const getContactedType = (source: string) => {
  if (source === SOURCE.bookstat) {
    return CONTACTED;
  } else {
    return CONTACTED_RR;
  }
};

const ToContacted: React.FC<IProps> = ({ book, id, refetchTable }) => {
  const { source: sourcePath, setActionLoading } = useActions();
  const contacted = createContactedObject(sourcePath['source'], book);
  const contactedType = getContactedType(sourcePath['source']);
  const { mutateAsync } = useUpdateTitle(contactedType);

  const onSend = (e) => {
    setActionLoading(true);
    e.stopPropagation();
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Send To Contacted' });
    mutateAsync(contacted)
      .then(res => {
        if (res?.status === 200) {
          notification.success({
            description: `Success: Sent ${book.Title}`,
            message: 'The Title was successfully sent to the Contacted tab.'
          });
          refetchTable();
        }
      })
      .catch(() => {
        window.gtag('event', 'exception',
          { event_category: 'Error', event_label: 'Error: Send To Contacted tab.' }
        );
        notification.error({
          description: `Error while sending ${book.Title} to the Contacted tab.`,
          message: 'Failed to send title.'
        });
        setActionLoading(false);
      });
  };

  const content = (
    <div className='popover-content'>Send Title to Contacted</div>
  );

  return (
    <Popover placement='topLeft' content={content} trigger='hover'>
      <PhoneOutlined
        data-testid='action-contacted-item'
        onClick={onSend}
        className='actions-icons actions-icons-contact'
      />
    </Popover>
  );
};

export default ToContacted;