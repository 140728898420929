import { GAEventIdentifier, GAEventName } from '../constants';

const sendGAFilterEvent = (a: any, sourcePath: string, tabId: string) => {
  const filterKey = Object.keys(a)[0];
  const source = sourcePath.replace('/', '');

  if (filterKey === `${tabId}search`) {
    const value = a[filterKey];

    if (!value || (typeof value === 'string' && !value.trim().length)) {
      return;
    }
    window.gtag('event', GAEventName.searchSubmit, {
      identifier: GAEventIdentifier.searchBox,
      value: a[filterKey],
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}amazonasin`) {
    const value = a[filterKey];

    if (!value || (Array.isArray(value) && !value.length)) {
      return;
    }

    window.gtag('event', GAEventName.filterASIN, {
      identifier: GAEventIdentifier.filterASIN,
      value: a[filterKey].join(', '),
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}genres` || filterKey === `${tabId}fiction_tags`) {
    const value = a[filterKey];


    if (!value || (Array.isArray(value) && !value.length)) {
      return;
    }

    window.gtag('event', GAEventName.filterGenres, {
      identifier: GAEventIdentifier.filterGenres,
      value: a[filterKey].join(', '),
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}tag` || filterKey === `${tabId}warning_tags`) {
    const value = a[filterKey];

    if (!value || (Array.isArray(value) && !value.length)) {
      return;
    }

    window.gtag('event', GAEventName.filterTags, {
      identifier: GAEventIdentifier.filterTags,
      value: a[filterKey].join(', '),
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}not_tag` || filterKey === `${tabId}not_warning_tags`) {
    const value = a[filterKey];

    if (!value || (Array.isArray(value) && !value.length)) {
      return;
    }

    window.gtag('event', GAEventName.filterTags, {
      identifier: GAEventIdentifier.filterTags,
      value: a[filterKey].join(', '),
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}days_tracked`) {
    const value = a[filterKey];

    if (!value || (typeof value === 'string' && !value.trim().length)) {
      return;
    }

    window.gtag('event', GAEventName.filterDaysTracked, {
      identifier: GAEventIdentifier.filterDaysTracked,
      value: a[filterKey],
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}late_bloomer`) {
    const value = a[filterKey];

    if (!value || (typeof value === 'string' && !value.trim().length)) {
      return;
    }

    window.gtag('event', GAEventName.filterAllTitles, {
      identifier: GAEventIdentifier.filterAllTitles,
      value: a[filterKey],
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}late_bloomer`) {
    const value = a[filterKey];

    if (!value || (typeof value === 'string' && !value.trim().length)) {
      return;
    }

    window.gtag('event', GAEventName.filterAllTitles, {
      identifier: GAEventIdentifier.filterAllTitles,
      value: a[filterKey],
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}type_tag`) {
    const value = a[filterKey];

    if (!value || (typeof value === 'string' && !value.trim().length)) {
      return;
    }

    window.gtag('event', GAEventName.filterTypesTag, {
      identifier: GAEventIdentifier.filterTypesTag,
      value: a[filterKey],
      event_category: tabId,
      page_source: source
    });
  }

  if (filterKey === `${tabId}status_tag`) {
    const value = a[filterKey];

    if (!value || (Array.isArray(value) && !value.length)) {
      return;
    }

    window.gtag('event', GAEventName.filterStatusTag, {
      identifier: GAEventIdentifier.filterStatusTag,
      value: a[filterKey].join(', '),
      event_category: tabId,
      page_source: source
    });
  }
};

export default sendGAFilterEvent;
