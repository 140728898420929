import {
  ALL_EXCLUDED,
  ALL_NEW_TITLE,
  BOOKSTAT_DATA,
  COMPETITORS,
  MANUALLY_ADDED,
  PENDING_EXCLUSION,
  PODIUM_SALES,
  PODIUM_TITLES,
  SEND_TO_CRM,
  WATCHLIST,
  AUTHORS,
  RISING_STARS,
  POPULAR,
  TRENDING,
  ALL_TITLES_RR,
  ALL_EXCLUDED_RR,
  SENT_TO_CRM_RR,
  WATCHLIST_RR,
  DAILY_RANK_EBOOKS,
  DAILY_RANK_AUDIO_BOOKS,
  DAILY_RANK_CATALOGUE,
  PODIUMAUTHORS,
  PENDING_EXCLUSION_RR,
  PODIUM_TITLES_RR,
  HOT_TITLES,
  CONTACTED,
  CONTACTED_RR
} from '../../core/constants';

export const REFETCHDELAY = 250;

export const RefetchOptions = { cancelRefetch: true };

export const BASEFONTSIZE = '13px';

const fontSize = ['8px', '10px', '12px', '13px', '14px', '16px'];

export const FONTSIZE = fontSize.map(size => ({ label: size, value: size }));

export const PAGESIZE = 100;

export const DAILY_RANK_DATE_FORMAT = 'YYYY-MM-DD';

interface IColumnsHeadin {
  [key: string]: string[];
}

const commonColumns = [
  'Added',
  'Days Tracked',
  'Title',
  'Author',
  'ASIN',
  'Genre',
  'Podium Hit Count',
  'Projected Sales',
  'Average Projected Sales',
  'Max Projected Sales',
  'Num. of Pages',
  'ISBN',
  'Publisher Type',
  'Series',
  'Date Pub',
  'Days On Sale',
  'Amzn Rating',
  'Amzn Review',
  'eBooks Sold',
  'Confidence',
  'Average Confidence',
  'Max Confidence',
  'Price',
  // 'Kindle Sales Rank',
  'Kindle Unlimited',
  'Tags'
];

const baseColumns = [
  'Added',
  'Days Tracked',
  'Title',
  'Author',
  'ASIN',
  'Genre',
  'Podium Hit Count',
  'Projected Sales',
  'Num. of Pages',
  'ISBN',
  'Publisher Type',
  'Series',
  'Date Pub',
  'Days On Sale',
  'Amzn Rating',
  'Amzn Review',
  'eBooks Sold',
  'Confidence'
];

const royalRoadCommonColumns = [
  'Added',
  'Title',
  'Author',
  'Patreon',
  'External Links',
  'First Published Date',
  'Genre',
  'Overall Score',
  'Grammar Score',
  'Views',
  'Followers',
  'Favorites',
  'Ratings',
  'Pages',
  'Type',
  'Warnings',
  'Status'
];

const royalRoadAuthorColumns = [
  'Added',
  'Author',
  'Fictions',
  'Words',
  'Reviews',
  'Author Ratings',
  'Followers',
  'Favorites',
  'Reputation Level',
  'Achievements',
  'Patreon',
  'Discord',
  'Twitter'
];

const dailyRankColumns = [
  'More',
  'Series',
  'Author',
  'ASIN',
  'Release Date',
  'Highest Rank',
  'Highest Rank Date',
  'Book #',
  'Kindle Unlimited?',
  'Tier',
  'Last Updated'
];

const bookstatCommonColumns = [
  'Added', 'Days Tracked', 'Title',
  'Author', 'ASIN', 'Genre', 'Sales Rank',
  'Podium Hit Count', 'Projected Sales', 'Average Projected Sales',
  'Max Projected Sales', 'Num. of Pages', 'Avg. Also Bought Bestseller Rank',
  'ISBN', 'Agent', 'Agency', 'Publisher Type',
  'Series', 'Date Pub', 'Days On Sale',
  'Amzn Rating', 'Amzn Review', 'eBooks Sold',
  'Confidence', 'Average Confidence',
  'Max Confidence', 'Price',
  'Kindle Unlimited', 'Tags'
];

export const COLUMNSHEADING: IColumnsHeadin = {
  [ALL_NEW_TITLE]: [...bookstatCommonColumns],
  [HOT_TITLES]: [...bookstatCommonColumns],
  [MANUALLY_ADDED]: [...bookstatCommonColumns.filter(col => col !== 'Avg. Also Bought Bestseller Rank')],
  [ALL_EXCLUDED]: [...commonColumns],
  [BOOKSTAT_DATA]: [...baseColumns],
  [COMPETITORS]: [...commonColumns],
  [PENDING_EXCLUSION]: [...commonColumns],
  [PODIUM_SALES]: [
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'First 12 months sales (units)',
    'Trailing 12 months sales (units)',
    'Lifetime sales (units)',
    'Lifetime sales (dollars)',
    'Audible Product ID',
    'ASIN',
    'Podium Hit Count',
    'Projected Sales',
    'Num. of Pages',
    'ISBN',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence'
  ],
  [PODIUM_TITLES]: [
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'ASIN',
    'Genre',
    'Podium Hit Count',
    'Projected Sales',
    'Average Projected Sales',
    'Max Projected Sales',
    'Num. of Pages',
    'Avg. Also Bought Bestseller Rank',
    'ISBN',
    'Agent',
    'Agency',
    'Publisher Type',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence',
    'Average Confidence',
    'Max Confidence',
    'Price',
    'Kindle Unlimited',
    'First 12 months sales (units)',
    'Trailing 12 months sales (units)',
    'Lifetime sales (units)',
    'Lifetime sales (dollars)',
    'Tags'
  ],
  [SEND_TO_CRM]: [
    'Sent to CRM on date',
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'ASIN',
    'Genre',
    'Podium Hit Count',
    'Projected Sales',
    'Average Projected Sales',
    'Max Projected Sales',
    'Num. of Pages',
    'Avg. Also Bought Bestseller Rank',
    'ISBN',
    'Publisher Type',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence',
    'Average Confidence',
    'Max Confidence',
    'Price',
    'Kindle Unlimited',
    'Assignee',
    'Tier',
    'Priority',
    'Tags'
  ],
  [WATCHLIST]: [
    'Added to Watchlist',
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'ASIN',
    'Genre',
    'Podium Hit Count',
    'Projected Sales',
    'Average Projected Sales',
    'Max Projected Sales',
    'Num. of Pages',
    'Avg. Also Bought Bestseller Rank',
    'ISBN',
    'Agent',
    'Agency',
    'Publisher Type',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence',
    'Average Confidence',
    'Max Confidence',
    'Price',
    'Kindle Unlimited',
    'Tags'
  ],
  [CONTACTED]: [
    'Added to Contacted',
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'ASIN',
    'Genre',
    'Podium Hit Count',
    'Projected Sales',
    'Average Projected Sales',
    'Max Projected Sales',
    'Num. of Pages',
    'Avg. Also Bought Bestseller Rank',
    'ISBN',
    'Agent',
    'Agency',
    'Publisher Type',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence',
    'Average Confidence',
    'Max Confidence',
    'Price',
    'Kindle Unlimited',
    'Tags'
  ],
  [AUTHORS]: [...royalRoadAuthorColumns],
  [PODIUMAUTHORS]: [...royalRoadAuthorColumns],
  [ALL_TITLES_RR]: [...royalRoadCommonColumns],
  [POPULAR]: [...royalRoadCommonColumns],
  [RISING_STARS]: [...royalRoadCommonColumns],
  [TRENDING]: [...royalRoadCommonColumns],
  [WATCHLIST_RR]: [...royalRoadCommonColumns],
  [CONTACTED_RR]: [...royalRoadCommonColumns],
  [SENT_TO_CRM_RR]: [...royalRoadCommonColumns],
  [PENDING_EXCLUSION_RR]: [...royalRoadCommonColumns],
  [PODIUM_TITLES_RR]: [...royalRoadCommonColumns],
  [ALL_EXCLUDED_RR]: [...royalRoadCommonColumns],
  [DAILY_RANK_EBOOKS]: [...dailyRankColumns],
  [DAILY_RANK_AUDIO_BOOKS]: [...dailyRankColumns, 'Narrator'],
  [DAILY_RANK_CATALOGUE]: [...dailyRankColumns]
};

export const FILETYPE = {
  csv: 'data:text/csv;charset=utf-8',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
};

export const FILEEXTENSION = {
  csv: '.csv',
  xlsx: '.xlsx'
};

export const EXPORTTYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const COLUMNINFO = {
  'Added': 'Date when the title was added to Brenda.',
  'Days Tracked': 'Number of days since the title was added.',
  'Projected Sales': 'Predicted sales value of the title for the next 50 days.',
  'Average Projected Sales': 'Average of the total projected sales to date.',
  'Max Projected Sales': 'Maximum of the total projected sales to date.',
  'Confidence': 'Probability of the title crossing the threshold value.',
  'Average Confidence': `Average probability of the title crossing the threshold value
    from the day it was added to Brenda.`,
  'Max Confidence': `Maximum probability of the title crossing the threshold value
    from the day it was added to Brenda.`
};

export const ExcludeParamKeys = ['option', 'tier', 'priority', 'createDeal', 'assignee'];

const baseFilters = {
  bookstat: ['search', 'amazonasin', 'tag', 'not_tag', 'genres', 'seriestitle', 'days_tracked'],
  royalroad: ['search', 'warning_tags', 'not_warning_tags',
    'fiction_tags', 'status_tag', 'type_tag'],
  authors: ['search', 'reputation', 'author_tags'],
  dailyRank: ['search', 'genre_categories', 'date_range', 'start_date', 'end_date',
    'min_rank', 'max_rank'],
  crm: ['createDeal', 'priority', 'tier', 'assignee']
};

export const allowedFiltersPerTab = {
  dailyRankEbooks: [...baseFilters.dailyRank],
  dailyRankAudioBooks: [...baseFilters.dailyRank],
  dailyRankCatalogue: [...baseFilters.dailyRank],
  allnewtitles: [...baseFilters.bookstat, ...baseFilters.crm, 'late_bloomer'],
  hotTitles: [...baseFilters.bookstat, ...baseFilters.crm],
  watchlist: [...baseFilters.bookstat, 'late_bloomer', ...baseFilters.crm],
  contacted: [...baseFilters.bookstat, 'late_bloomer', ...baseFilters.crm],
  pendingexclusion: [...baseFilters.bookstat, 'late_bloomer'],
  allexcluded: [...baseFilters.bookstat],
  manuallyadded: [...baseFilters.bookstat],
  competitors: [...baseFilters.bookstat],
  podiumtitles: [...baseFilters.bookstat],
  sendtocrm: [...baseFilters.bookstat],
  bookstatdata: ['search', 'amazonasin', 'genres', 'days_tracked'],
  podiumsales: ['search', 'amazonasin', 'days_tracked'],
  alltitlesRR: [...baseFilters.royalroad],
  watchlistRR: [...baseFilters.royalroad],
  contactedRR: [...baseFilters.royalroad],
  popular: [...baseFilters.royalroad],
  risingStars: [...baseFilters.royalroad],
  trending: [...baseFilters.royalroad],
  authors: [...baseFilters.authors],
  podiumAuthors: [...baseFilters.authors],
  pendingExclusionRR: [...baseFilters.royalroad],
  excludedRR: [...baseFilters.royalroad],
  podiumtitlesRR: [...baseFilters.royalroad],
  senttocrmRR: [...baseFilters.royalroad]
};

export const GAEventName = {
  click: 'click',
  hover: 'hover',
  exception: 'exception',
  viewItem: 'view_item',
  actionDelete: 'action_delete',
  actionBlock: 'action_block',
  searchSubmit: 'search_submit',
  filterASIN: 'filter_asins',
  filterGenres: 'filter_genres',
  filterTags: 'filter_tags',
  filterExcludeTags: 'filter_exclude',
  filterDaysTracked: 'filter_days_tracked',
  filterAllTitles: 'filter_all_titles',
  filterTypesTag: 'filter_type',
  filterStatusTag: 'filter_status'
};

export const GAEventIdentifier = {
  delete: 'Delete',
  blockAuthor: 'Block Author',
  titletoPendingExclusion: 'Send Title to Pending Exclusion',
  titletoPodiumTitles: 'Send Title to Podium Titles',
  titletoWatchlist: 'Send Title to Watchlist',
  titletoCRM: 'Send Title to CRM',
  podiumHitCount: 'Podium Hit Count',
  searchBox: 'Search Box',
  filterASIN: 'ASINs Dropdown',
  filterGenres: 'Genres Dropdown',
  filterTags: 'Tags Dropdown',
  filterExcludeTags: 'Exclude Tags Dropdown',
  filterDaysTracked: 'Days Tracked Dropdown',
  filterAllTitles: 'All Titles Dropdown',
  filterTypesTag: 'Type Dropdown',
  filterStatusTag: 'Status Dropdown',
  openAddTitle: 'Open Add Title',
  openDashboard: 'Open Dashboard',
  openAdminDashboard: 'Open Admin Dashboard',
  openFeedback: 'Open Feedback',
  openManageUsers: 'Open Manage Users',
  openHamburgerMenu: 'Open Hamburger Menu',
  openAddUsers: 'Open Add User',
  chart: 'charts',
  resetForm: 'Reset Form',
  editKeyword: 'Edit Keyword Action',
  exportData: 'Export Data',
  saveSearch: 'Save Seach Results',
  deleteSearch: 'Delete Search Result',
  openSettings: 'Open Settings',
  sortItem: 'Sort Items',
  sortUser: 'Sort User',
  openUserPermissions: 'Open User Role & Permissions',
  createUser: 'Create User',
  errorCreateUser: 'Error Creating User',
  deleteUser: 'Delete User',
  errorDeleteUuser: 'Error Deleting User',
  editUser: 'Edit User',
  errorEditUser: 'Error Edit User',
  createSuperUser: 'Super User: Create User',
  deleteSuperUser: 'Super User: Open Delete User'
};