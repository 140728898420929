import React from 'react';
import { Popover } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { IBook } from '../../section/Common/types/types';
import { useReturn } from '../../core/modules/book/hooks/useReturn.query';
import { useActions } from '../../context/Actions.context';
import { GAEventName } from '../../section/Common/constants';

interface IProps {
  book: IBook;
  id: string;
}

const Return: React.FC<IProps> = ({ book, id }) => {
  const { source: sourcePath, setActionLoading } = useActions();
  const book_ids = book.ASIN ? book.ASIN : book['Fiction Id'];
  const { mutate } = useReturn({ book_ids, tabId: id, source: sourcePath['source'] });
  const isManuallyAdded = book?.['Secondary Tags']?.includes('Manually Added');
  let returnTo = 'All New titles';

  if (isManuallyAdded) {
    returnTo = 'Manually Added';
  } else if (sourcePath['source']?.includes('royal-road')) {
    returnTo = 'All Titles';
  }

  return (
    <Popover
      placement='topLeft'
      content={
        <div className='popover-content'>
          {`Return title to ${returnTo}`}
        </div>
      }
      trigger='hover'
    >
      <RollbackOutlined
        data-testid='action-return-item'
        onClick={(e) => {
          setActionLoading(true);
          e.stopPropagation();
          window.gtag('event', GAEventName.click,
            { identifier: `Return title to ${returnTo}`, book_name: book.Title, value: book.ASIN });
          mutate();
        }}
        className='actions-icons actions-icons-return'
      />
    </Popover>
  );
};

export default React.memo(Return);