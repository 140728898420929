import { AutoComplete, DatePicker, Form, Modal, Popover, Select } from 'antd';
import { useState, useCallback, useEffect } from 'react';
import RankingsTable from './RankingsTable';
import { useQueryClient } from 'react-query';
import { MoreOutlined } from '@ant-design/icons';
import { useCategoryRankings } from '../../core/modules/book/hooks/useCategoryRankings';
import debounce from 'debounce';
import { DAILY_RANK_DATE_FORMAT } from '../../section/Common/constants';
import { validCustomDateRange } from '../../section/Common/utils/validCustomDateRange';
import moment from 'moment';

const RankingsTableModal = ({ asin }) => {
  const [days, setDays] = useState<number | undefined>();
  const [dateRange, setDateRange] = useState<
    undefined | { startDate: string; endDate: string }
  >();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { data, isLoading, isFetching, refetch } = useCategoryRankings(
    asin,
    days,
    dateRange
  );
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const handleSearch = useCallback(() => {
    if (!calendarOpen) {
      refetch();
    }
  }, [calendarOpen]);

  useEffect(() => {
    if (open) {
      handleSearch();
    }
  }, [days, dateRange, open]);

  const showRankingsTable = () => {
    handleSearch();
    setOpen(true);
  };

  const handleCancel = () => {
    queryClient.cancelQueries({ queryKey: ['get/category-rankings'] });
    setOpen(false);
    form.resetFields();
  };

  const onSearch = debounce((value: number | undefined) => {
    setDays(value);
  }, 2000);

  const onFormChange = (e) => {
    form.setFieldsValue(e.days);
    onSearch(e.days);
  };

  const handleRangeChange = (dates) => {
    setDateRange(undefined);

    if (validCustomDateRange(dates)) {
      setDateRange({ startDate: moment(dates[0]).format(DAILY_RANK_DATE_FORMAT),
        endDate: moment(dates[1]).format(DAILY_RANK_DATE_FORMAT) });
      form.setFieldsValue({ days: '' });
      setDays(undefined);
    }
  };

  const FilterDateRange = (
    <Form
      data-testid={'date-filter-form'}
      form={form}
      className='filter daily-rank-filter'
      onKeyPress={(e) => {
        if (e.code === 'Enter') {
          e.preventDefault();
        }
      }}
      onValuesChange={(e) => onFormChange(e)}
    >
      <Form.Item name={'days'}>
        <AutoComplete
          className='filter-select'
          placeholder='Date Range'
          showArrow
          allowClear
          disabled={Boolean(dateRange)}
        >
          {
            [...Array(11)].map((_, i) => i * 30 + 30).map((val) =>
              <Option value={val} key={val}>{val} days</Option>)
          }
          <Option value={365}>365 days</Option>
        </AutoComplete>
      </Form.Item>
      <RangePicker
        style={{
          borderRadius: '8px',
          padding: '9px'
        }}
        format={DAILY_RANK_DATE_FORMAT}
        onCalendarChange={handleRangeChange}
        onOpenChange={(calOpen) => {
          setCalendarOpen(calOpen);
        }}
      />
    </Form>
  );

  return (
    <>
      <Popover
        content={<div className='popover-content'>Daily Category Ranks Table</div>}
        trigger='hover'
        placement='right'
      >
        <MoreOutlined
          data-testid='open-btn'
          onClick={showRankingsTable}
          className='actions-icons actions-icons-rankings'
        />
      </Popover>

      <Modal
        title='Daily Rank Check: Category Rankings'
        okText='Ok'
        visible={open}
        className={'category-rankings'}
        onCancel={handleCancel}
        width={'90%'}
        footer={true}
      >
        <div style={{ margin: '10px' }}>
          {FilterDateRange}
        </div>
        <RankingsTable
          isLoading={isLoading}
          isFetching={isFetching}
          data={data}
        />
      </Modal>
    </>
  );
};

export default RankingsTableModal;