import React from 'react';
import { Popover } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { IBook } from '../../../section/Common/types/types';
import { ACTIONS, useActions } from '../../../context/Actions.context';
import { GAEventIdentifier, GAEventName } from '../../../section/Common/constants';

interface IProps {
  id: string;
  book: IBook;
}

const BlockAuthor: React.FC<IProps> = ({ id, book }) => {
  const { setAction } = useActions();

  return (
    <Popover
      placement='top'
      content={
        <div className='popover-content'>Block Author</div>
      }
      trigger='hover'
    >
      <StopOutlined
        onClick={(e) => {
          e.stopPropagation();
          window.gtag('event', GAEventName.actionBlock, { identifier: GAEventIdentifier.blockAuthor,
            value: book.Author });
          setAction({ book, id, type: ACTIONS.blockAuthor });
        }}
        className='actions-icons actions-icons-trash'
      />
    </Popover>
  );
};

export default React.memo(BlockAuthor);