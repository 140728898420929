import Button from '../../components/Button';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ModalUserPermissions from './ModalUserPermissions';
import { GAEventIdentifier, GAEventName } from '../Common/constants';

const UsersPermissions = ({ rolesAndPermissions, isLoadingRoles }) => {
  const [modal, setModal] = useState({ open: false });

  return (
    <>
      <ModalUserPermissions
        open={modal?.open}
        setOpen={setModal}
        rolesAndPermissions={rolesAndPermissions}
        isLoadingRoles={isLoadingRoles}
      />
      <Button
        type='primary'
        onClick={() => {
          window.gtag('event', GAEventName.click,
            { event_category: 'Interaction', identifier: GAEventIdentifier.openUserPermissions });
          setModal({ open: true });
        }}
      >
        <SafetyCertificateOutlined /> View Users Permissions
      </Button>
    </>
  );
};

export default UsersPermissions;