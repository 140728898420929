import React from 'react';
import styled from 'styled-components';
import Table from '../../components/Table';
import { SpinnerTabs } from '../../composed-components';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useDashboardInfoTable } from '../../core/modules/dashboard/hooks/useDashboardInfoTable';

const DashboardWrapper = styled.div`
  width: 100%;
`;

const Text = styled.p`
  font-weight: 600;
  margin: 20px 10px;
`;

const popoverInfo = (content: React.ReactNode, title: string) => (
  <p>
    {title}
    <Popover
      trigger='hover'
      placement='right'
      overlayStyle={{
        maxWidth: '500px'
      }}
      content={content}
    >
      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
    </Popover>
  </p>
);

const rowName = {
  'all-excluded': 'Titles Sent to Excluded List',
  'all-new-titles': 'All New Titles Identified',
  deleted: 'Titles Deleted',
  'podium-titles': 'Podium Titles Tracked',
  'sent-to-crm': 'Titles Sent to HubSpot',
  watchlist: 'Titles Added to Watchlist',
  'title-identification-ratio': popoverInfo(
    <>
      Measures how efficiently the team is identifying and processing potential
      titles for acquisition. <br/>Formula:<br/>Number of titles sent to HubSpot from
      Brenda /<br/>Total number of titles added to “All New Titles” in Brenda.
    </>,
    ' Title Identification Efficiency Ratio'
  ),
  acquisitions_velocity: popoverInfo(
    <>
      Measures average time (in days) from a title being sent to HubSpot to
      reach the Closed Won (Acquired) stage in the Podium Acquisitions Pipeline.
    </>,
    'Acquisition Pipeline Velocity'
  ),
  acquisition_efficiency: popoverInfo(
    <>
      Identifies the percentage of titles sent to HubSpot that were successfully
      acquired.<br/>Formula:<br/>Titles in the “Closed Won (Acquired)” stage (last “x”
      days) /<br/>total titles sent to HubSpot (last “x” days).
    </>,
    'Acquisition Efficiency'
  )
};

const formatNumber = (value: number) => {
  if (typeof value === 'number') {
    return Number(value).toLocaleString();
  } else {
    return value;
  }
};

const getDataSource = (data: Record<string, object>) =>
  data &&
  Object.entries(data).reduce(
    (acc: Array<object>, [key, value]) =>
      !rowName[key]
        ? acc
        : [
          ...acc,
          {
            key,
            name: rowName[key],
            '30': formatNumber(value?.['30']),
            '60': formatNumber(value?.['60']),
            '90': formatNumber(value?.['90']),
            '120': formatNumber(value?.['120'])
          }
        ],
    []
  );

const columns = [
  {
    title: 'Data',
    dataIndex: 'name',
    key: 'name',
    width: '400px'
  },
  {
    title: '30d',
    dataIndex: '30',
    key: '30'
  },
  {
    title: '60d',
    dataIndex: '60',
    key: '60'
  },
  {
    title: '90d',
    dataIndex: '90',
    key: '90'
  },
  {
    title: '120d',
    dataIndex: '120',
    key: '120'
  }
];

const DashboardSection: React.FC = () => {
  const { data, isLoading } = useDashboardInfoTable();
  const dataSource = getDataSource(data?.data);

  return !isLoading ? (
    <DashboardWrapper>
      {Boolean(data?.last_bookstat_date) && (
        <Text>Last Bookstat data retrieval: {data?.last_bookstat_date} </Text>
      )}
      {data && (
        <Table
          bordered
          dataSource={dataSource || []}
          pagination={false}
          columns={columns}
        />
      )}
    </DashboardWrapper>
  ) : (
    <SpinnerTabs isLoading={isLoading} />
  );
};

export default DashboardSection;
