import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';

const useAllUserRoles = () => {
  const {
    user: { getAllUserRoles }
  } = useServices();

  const { data, error, isLoading } = useQuery(
    ['get-users-roles-permissions'],
    getAllUserRoles,
    {
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error('Error fetching user roles:', err);
      }
    }
  );

  const userRolesPermissions = data?.data?.user_roles_and_permissions || [];
  const userRolesList = data?.data?.user_roles_and_permissions?.map(role => role.user_role) || [];

  return { userRolesList, userRolesPermissions, isLoading, error };
};

export default useAllUserRoles;