import React from 'react';
import { FlagOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { IBook } from '../../../section/Common/types/types';
import { ACTIONS, useActions } from '../../../context/Actions.context';
import { GAEventName } from '../../../section/Common/constants';

interface IProps {
  book: IBook;
  id: string;
  label?: string;
}

const Exclude: React.FC<IProps> = ({ book, id, label = 'Title' }) => {
  const { setAction } = useActions();

  return (
    <Popover
      placement='topLeft'
      content={
        <div className='popover-content'>{`Send ${label} to Exclusion List`}</div>
      }
      trigger='hover'
    >
      <FlagOutlined
        data-testid='action-exclusion-list'
        onClick={(e) => {
          e.stopPropagation();
          window.gtag('event', GAEventName.click,
            { identifier: ACTIONS.exclude, value: book.ASIN, book_name: book.Title });
          setAction({ book, id, type: ACTIONS.exclude });
        }}
        className='actions-icons actions-icons-flag'
      />
    </Popover>
  );
};

export default React.memo(Exclude);