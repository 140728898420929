import { SendOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { Popover } from 'antd';
import './styles.css';
import { IBook } from '../../../section/Common/types/types';
import { ACTIONS, useActions } from '../../../context/Actions.context';
import { useFilter } from '../../../context/Filter.context';
import { clearCRMFilterValues, isCreateDealValid } from '../../../section/Common/utils/validateCRMFilter';
import { GAEventIdentifier, GAEventName } from '../../../section/Common/constants';

interface IProps {
  book: IBook;
  refetchTable?: Function;
  id: string;
}

const ToCRM: React.FC<IProps> = React.memo(({ book, id }) => {
  const { setAction } = useActions();
  const { filter, setFilter } = useFilter();

  useEffect(() => {
    const crmFilter = filter[id] || {};
    const { createDeal, tier, priority, amazonasin, assignee } = crmFilter;

    if (isCreateDealValid({ createDeal, tier, priority, amazonasin })) {
      const updateCrmData = {
        ...book,
        tier,
        priority,
        assignee
      };
      setAction({ book: updateCrmData, id, type: ACTIONS.toCRM });
    } else if (createDeal || priority || tier) {
      const updatedFilter = {
        ...filter,
        [id]: clearCRMFilterValues(filter[id])
      };
      setFilter(updatedFilter);
    }
  }, [filter[id]]);

  return (
    <Popover
      placement='topLeft'
      content={<div className='popover-content'>Send Title to CRM</div>}
      trigger='hover'
    >
      <SendOutlined
        data-testid='send-to-crm'
        onClick={(e) => {
          e.stopPropagation();
          window.gtag('event', GAEventName.click,
            { identifier: GAEventIdentifier.titletoCRM, value: book.ASIN, book_name: book.Title });
          setAction({ book, id, type: ACTIONS.toCRM });
        }}
        className='actions-icons actions-icons-send'
      />
    </Popover>
  );
});

export default ToCRM;