import Modal from '../../components/Modal';
import { Field, Form, Formik } from 'formik';
import { notification } from 'antd';
import FormItem from '../../components/FormItem';
import { Option, Select } from '../../components/Select';
import { Checkbox } from '../../components/Checkbox';
import Button from '../../components/Button';
import styled from 'styled-components';
import { useServices } from '../../core/services/useServices';
import { useMutation, useQueryClient } from 'react-query';
import Input from '../../components/Input';
import { required } from '../../utils/validation';
import { GAEventIdentifier, GAEventName } from '../Common/constants';

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  padding-top: 12px;
`;

const validateEmail = value => {
  let error = '';

  if (!value) {
    error = 'Required';
  } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

const ModalCreateUser = ({ user, open, setOpen, roles, isLoadingRoles }) => {
  const { user: { createUser } } = useServices();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: (response) => {
      setOpen(false);

      if (response?.data?.length) {
        notification?.success({
          message: 'Successfully added user.',
          description: response?.msg
        });

        return queryClient.refetchQueries('get-all-users');
      } else {
        notification?.error({
          message: 'Failed to add user.',
          description: response?.msg
        });
      }
    },
    onError: () => {
      setOpen(false);
      notification?.error({
        description: 'An error occurred while creating the user. Please try again.',
        message: ''
      });
      window.gtag('event', GAEventName.exception,
        { event_category: 'Error', identifier: GAEventIdentifier.errorCreateUser });
    }
  });

  const handleEdit = async (values) => {
    window.gtag('event', GAEventName.click,
      { event_category: 'Interaction', identifier: GAEventIdentifier.createUser });
    mutate(values);
  };

  return (
    <Modal
      okText='Ok'
      title='Add new user'
      visible={open}
      footer={null}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          role: '',
          active: 1,
          email: '',
          ...user
        }}
        enableReinitialize
        onSubmit={e => e.preventDefault()}
      >
        {({ values, errors, resetForm, dirty }) => (
          <Form>
            <FormItem label='First name' error={errors?.first_name} required>
              <Field name={'first_name'} validate={required}>
                {({ field: { value, onChange, ...rest } }) => (
                  <Input
                    value={value}
                    placeholder='First name'
                    onChange={event => {
                      onChange(event);
                    }}
                    {...rest}
                  />
                )}
              </Field>
            </FormItem>
            <FormItem label='Last name' error={errors?.last_name} required>
              <Field name={'last_name'} validate={required}>
                {({ field: { value, onChange, ...rest } }) => (
                  <Input
                    value={value}
                    placeholder='Last name'
                    onChange={event => {
                      onChange(event);
                    }}
                    {...rest}
                  />
                )}
              </Field>
            </FormItem>
            <FormItem label='Email' error={errors?.email} required>
              <Field name={'email'} validate={validateEmail}>
                {({ field: { value, onChange, ...rest } }) => (
                  <Input
                    value={value}
                    placeholder='Email'
                    onChange={event => {
                      onChange(event);
                    }}
                    {...rest}
                  />
                )}
              </Field>
            </FormItem>
            <FormItem label='Role' error={errors?.role} required>
              <Field name={'role'} validate={required}>
                {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                  <Select
                    placeholder='Select role'
                    value={value === '' || value === null ? undefined : value}
                    {...rest}
                    onChange={v => {
                      setFieldValue('role', v);
                    }}
                  >
                    {isLoadingRoles ? (
                      <Option key='loading' value='loading' disabled={true}>
                          Loading...
                      </Option>
                    ) : (
                      roles.map(role => (
                        <Option key={role} value={role}>
                          {role}
                        </Option>
                      )))}
                  </Select>
                )}
              </Field>
            </FormItem>
            <Field name={'active'}>
              {({
                field: { value, ...restProps },
                form: { setFieldValue, setFieldTouched }
              }) => (
                <Checkbox
                  name={'active'}
                  checked={value}
                  defaultChecked
                  onChange={event => {
                    setFieldValue('active', event.target.checked);
                    setFieldTouched('active', true, false);
                  }}
                  {...restProps}
                >
                  Active
                </Checkbox>
              )}
            </Field>

            <Footer>
              <Button
                onClick={() => {
                  resetForm();
                  setOpen(false);
                }}
              >
                Cancel
              </Button>

              <Button
                onClick={() => handleEdit(values)}
                disabled={Boolean(Object.values(errors)?.length) || !dirty}
                type='primary'
                loading={isLoading}
              >
                Ok
              </Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalCreateUser;