import Button from '../../components/Button';
import { UserAddOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ModalCreateUser from './ModalCreateUser';
import { GAEventIdentifier, GAEventName } from '../Common/constants';

const AddNewUser = ({ roles, isLoadingRoles }) => {
  const [modal, setModal] = useState({ open: false, user: {} });

  return (
    <>
      <ModalCreateUser
        user={{}}
        open={modal?.open}
        setOpen={setModal}
        roles={roles}
        isLoadingRoles={isLoadingRoles}
      />
      <Button
        type='primary'
        onClick={() => {
          window.gtag('event', GAEventName.click,
            { event_category: 'Interaction', identifier: GAEventIdentifier.openAddUsers });
          setModal({ open: true, user: {} });
        }}
      >
        <UserAddOutlined /> Add user
      </Button>
    </>
  );
};

export default AddNewUser;